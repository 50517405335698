import React, {Component} from 'react'
import './GameChangerSection.css'
import {Button, Grid, Image} from "semantic-ui-react";
import {HashLink as Link} from "react-router-hash-link";
import Tracker from "../../trackUtils";


export default class GameChangerSection extends Component {

    render() {


        return (
            <div className='game-changer-section-wrapper'>

                <div className='section-content-wrapper'>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='game-changer-header'>
                            The game changer.<br/>
                            <span>Privacy at the next level.</span>
                        </div>
                    </div>


                    <Image className='game-changer-section-img' src='/images/3-areas-of-privacy-protection.png'/>


                    <Grid
                        stackable
                        className='game-changer-grid'
                        style={{
                            // width: "90%",
                            // background: 'green',
                            margin: '0 auto'}}
                        columns={3}
                    >

                        <Grid.Column tablet={8} computer={5} className='game-changer-column-content'>

                            <div>

                                <div className='game-changer-column-header'>Websites</div>

                                <div className='game-changer-column-text'><strong>You can</strong> control cookies<br className='no-mobile'/> and tracking Ads in your browser.</div>

                            </div>

                        </Grid.Column>

                        <Grid.Column tablet={8} computer={5} className='game-changer-column-content' >

                            <div>

                                <div className='game-changer-column-header'>Connection</div>

                                <div className='game-changer-column-text' ><strong>You can</strong> stay secure and anonymous online with VPN solutions.</div>

                            </div>

                        </Grid.Column>

                        <Grid.Column tablet={16} computer={6} className='game-changer-column-content game-changer-column-cta' style={{backgroundColor: '#EDF8F8'}}>

                            <div style={{
                                // background: 'red',
                                display: 'flex', alignItems: 'center', flexDirection: 'column'}} >

                                <div className='game-changer-column-header game-changer-column-cta'>Data trading and leaks</div>

                                <div className='game-changer-column-text'>
                                    <strong>You can</strong> control how data about you is being processed by external companies.
                                </div>

                                <div className='game-changer-column-text'>
                                    Our solution allows you to be yourself and fully enjoy online life without worrying about these invisible threads. If a data breach happens - you're safe.
                                </div>

                                <div className='game-changer-section-cta-wrapper'>
                                    <Link smooth to="#join" onClick={() => { Tracker.trackGoal('game-changer-protect-your-data')}}>
                                        <Button className='game-changer-section-cta-button'>
                                            Protect Your Data
                                        </Button>
                                    </Link>
                                </div>

                            </div>

                        </Grid.Column>

                    </Grid>

                    {/*<div className='game-changer-section-cta-wrapper'>*/}
                    {/*<Button className='game-changer-section-cta-button'>*/}
                    {/*Protect Your Data*/}
                    {/*</Button>*/}
                    {/*</div>*/}

                </div>

            </div>
        )
    }
}