import React, {Component} from 'react'
import './FooterSection.scss'
import {Image} from "semantic-ui-react";
import Tracker from "../../trackUtils";

export default class FooterSection extends Component {

    constructor(props) {
        super(props);
    }

    handleItemClick = (name) => () => {
        Tracker.trackGoal('footer-' + name);
        this.props.history.push(name)
    }

    render() {


        return (

            <div className='section-wrapper footer-section'>

                <div className='section-content footer-section'>

                    <div className='contact-info'>
                        <img className='contact-info-logo' src='/images/logo-small.png'/>

                        <div className='contact-info-email'>
                            Contact email: <a href="mailto:hello@noid.today"> hello[at]noid.today</a>
                        </div>

                        <div className='contact-info-company'>
                            © 2022 no.ID ehf - Iceland
                        </div>
                    </div>

                    <div className='footer-info'>

                        <div className='footer-col'>

                           <div className='footer-col-header'>KNOWLEDGE</div>
                           <ul>
                               <li className='footer-column-text' onClick={this.handleItemClick("whitepaper")}>
                                   White Paper
                               </li>
                               <li className='footer-column-text' onClick={this.handleItemClick("blog")}>
                                   Blog
                               </li>
                               <li className='footer-column-text' onClick={this.handleItemClick("case-study")}>
                                   Case Study
                               </li>
                           </ul>

                        </div>

                        <div className='footer-col'>

                            <div className='footer-col-header'>PRODUCT</div>
                            <ul>
                                <li className='footer-column-text' onClick={this.handleItemClick("pricing")}>
                                    Pricing
                                </li>
                                <li className='footer-column-text' onClick={this.handleItemClick("demo")}>
                                    Demo
                                </li>
                            </ul>

                        </div>

                        <div className='footer-col'>

                            <div className='footer-col-header'>COMPANY</div>
                            <ul>
                                {/*<li className='footer-column-text' onClick={this.handleItemClick("about-us")}>*/}
                                {/*    About us*/}
                                {/*</li>*/}
                                <li className='footer-column-text' onClick={this.handleItemClick("contact")}>
                                    Contact
                                </li>
                                <li className='footer-column-text' onClick={this.handleItemClick("careers")}>
                                    Careers
                                </li>
                            </ul>

                        </div>

                        <div className='footer-col'>

                            <div style={{textAlign: 'left'}}>
                                <div className='footer-col-header'>LEGAL</div>
                                <ul>
                                    <li className='footer-column-text' onClick={this.handleItemClick("terms-of-service")}>
                                        Terms of Service
                                    </li>
                                    <li className='footer-column-text' onClick={this.handleItemClick("privacy-policy")}>
                                        Privacy Policy
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>


                    {/*            <Grid.Column mobile={8} tablet={8} computer={4} className='footer-column'>*/}

                    {/*                <div className='footer-column-header'>KNOWLEDGE</div>*/}

                    {/*                <List>*/}
                    {/*                    <List.Item className='footer-column-text' onClick={this.handleItemClick("whitepaper")}>*/}
                    {/*                        White Paper*/}
                    {/*                    </List.Item>*/}
                    {/*                    <List.Item className='footer-column-text' onClick={this.handleItemClick("blog")}>*/}
                    {/*                        Blog*/}
                    {/*                    </List.Item>*/}
                    {/*                    <List.Item className='footer-column-text' onClick={this.handleItemClick("case-study")}>*/}
                    {/*                        Case Study*/}
                    {/*                    </List.Item>*/}
                    {/*                </List>*/}

                    {/*            </Grid.Column>*/}

                    {/*            <Grid.Column mobile={8} tablet={8} computer={4} className='footer-column'>*/}

                    {/*                <div className='footer-column-header'>COMPANY</div>*/}

                    {/*                <List>*/}
                    {/*                    <List.Item className='footer-column-text' onClick={this.handleItemClick("about-us")}>About us</List.Item>*/}
                    {/*                    <List.Item className='footer-column-text' onClick={this.handleItemClick("contact")}>Contact</List.Item>*/}
                    {/*                    <List.Item className='footer-column-text' onClick={this.handleItemClick("careers")}>Careers</List.Item>*/}
                    {/*                </List>*/}

                    {/*            </Grid.Column>*/}

                    {/*        </Grid.Row>*/}

                    {/*    <Grid.Row>*/}
                    {/*        <Grid.Column only='computer' computer={8}/>*/}

                    {/*        <Grid.Column tablet={8} computer={4} className='footer-column'>*/}

                    {/*            <div className='footer-column-header'>PRODUCT</div>*/}

                    {/*            <List>*/}
                    {/*                <List.Item className='footer-column-text' onClick={this.handleItemClick("pricing")}>Pricing</List.Item>*/}
                    {/*                <List.Item className='footer-column-text' onClick={this.handleItemClick("demo")}>DEMO</List.Item>*/}
                    {/*            </List>*/}

                    {/*        </Grid.Column>*/}

                    {/*        <Grid.Column width={4} className='footer-column'>*/}

                    {/*            <div className='footer-column-header'>LEGAL</div>*/}

                    {/*            <List>*/}
                    {/*                <List.Item className='footer-column-text' onClick={this.handleItemClick("terms-of-service")}>Terms of Service</List.Item>*/}
                    {/*                <List.Item className='footer-column-text' onClick={this.handleItemClick("privacy-policy")}>Privacy Policy</List.Item>*/}
                    {/*            </List>*/}

                    {/*        </Grid.Column>*/}

                    {/*    </Grid.Row>*/}

                    {/*</Grid>*/}

                </div>

            </div>
        )
    }
}