import React, { Component } from 'react'
import { PulseLoader } from 'react-spinners';
import { Button, Icon, Input } from "semantic-ui-react";
import Tracker from "../../trackUtils";
import styles from "./SubscriptionForm.module.scss";
import * as EmailValidator from 'email-validator';
import $ from 'jquery';

export default class SubscriptionForm extends Component {

    constructor(props) {

        super(props);

        this.state = {
            invalidEmail: false,
            email: '',

            showSuccessMsg: false,
            showError: false,
            inProgress: false,
            showErrorWrongEmail: false,

            checked: false,
            aggreementError: false
        }

        this.handleSubscribeClick = this.handleSubscribeClick.bind(this);
        this.onError = this.onError.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }

    onValueChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    subscribe() {

        this.props.history.push(`/thank-you`);
        return;
    
            const body = {
                email : this.state.email
            }
            const _ = this;
    
            $.ajax({
                url: 'https://noid.activehosted.com/proc.php?u=1&f=1&s=&c=0&m=0&act=sub&v=2&or=a417cd589db826ed64c7f95cc86df7a8',
                type: 'GET',
                data: body,
                crossDomain: true,
                cache: false,
                dataType: 'jsonp',
                // contentType: "application/json; charset=utf-8",
                async: true,
                success: function (data) {
                    // console.log("Inside response");
                    console.log("data: " + JSON.stringify(data));
                    if (data['result'] != "success") {
                        //ERROR
                        _.onError(data['msg'])
                    } else {
                        //SUCCESS - Do what you like here
                        _.onError();
                    }
                },
                error: function(xhr, status, err) {
                    _.onSuccess();
                    // _.onError();
                    // console.log("xhr : " + JSON.stringify(xhr, null, 3));
                    // console.log("status : " + JSON.stringify(status, null, 3));
                    // console.log("ERROR : " + JSON.stringify(err));
    
                    // var json = JSON.parse(xhr.responseText);
                    // console.log("JSON : " + JSON.stringify(json));
                }
            });
    }

    handleSubscribeClick(e) {
        e.preventDefault();

        this.setState({
            aggreementError: false,
            showErrorWrongEmail: false
        });

        if (this.state.inProgress) {
            return;
        }


        const email = this.state.email;
        if (!EmailValidator.validate(email)) {
            console.log("Wrong email");
            this.setState({
                inProgress : false,
                showErrorWrongEmail: true
            });
            return;
        }

        if (!this.state.checked) {
            this.setState({aggreementError: true});
            return;
        }

        Tracker.trackGoal('subscribe');

        this.setState({
            inProgress: true,
            showError: false,
            showSuccessMsg: false,
            showErrorWrongEmail: false,
            aggreementError: false
        })

        this.subscribe();
    }

    onSuccess() {
        Tracker.trackGoal('subscribe-success');
        this.setState({
            inProgress: false,
            showError: false,
            showErrorWrongEmail: false,
            showSuccessMsg: true
        });
    }

    onError(errorMessage) {

        if (errorMessage) {
            if (errorMessage.includes('already subscribed')) {
                Tracker.trackGoal('subscribe-failure-already-subscribed');
            } else {
                Tracker.trackGoal('subscribe-failure-mailchimp-error');
            }
        } else {
            Tracker.trackGoal('subscribe-failure');
        }

        this.setState({
            inProgress: false,
            showError: true,
            showErrorWrongEmail: false,
            showSuccessMsg: false
        });
    }

    handleCheckChange() {
        this.setState(prevState => ({
            checked: !prevState.checked
        }));
    }

    render() {
        return (
            <div>
                <div className={`${styles.subscribeSectionForm}`}>

                    <Input
                        className={`${styles.subscribeInput}`}
                        disabled={this.state.inProgress}
                        name='email' placeholder='Enter your email address' value={this.state.email}
                        onChange={this.onValueChange}
                    />

                    <Button
                        className={`${styles.subscribeSectionCtaButton}`}
                        disable={this.state.inProgress}
                        onClick={this.handleSubscribeClick}>

                        {!this.state.inProgress && ("Add me to the waitlist")}
                        {this.state.inProgress && (
                            <PulseLoader
                                size={10}
                                color={"#ffffff"}
                                loading={true}
                            />
                        )}

                    </Button>
                        
                    <div className={`${styles.agreementWrapper} ${this.state.aggreementError ? styles.error : ''}`}>
                        <input type="checkbox" disabled={this.state.inProgress}
                                checked={this.state.checked} onChange={this.handleCheckChange} />
                        <div>Yes, sign me up. I agree to the privacy policy terms</div>
                    </div>

                </div>

                <div className={`${styles.subscribeOutputWrapper}`}>
                    {this.state.showSuccessMsg && (
                        <div className={`${styles.subscribeSuccess}`}>
                            <Icon name='check circle' />
                            Great! Now please wait for your invitation.</div>
                    )}

                    {this.state.showError && (
                        <div className={`${styles.subscribeError}`}>
                            <Icon name='warning sign' />
                            Problem occurred. Please try again</div>
                    )}

                    {this.state.showErrorWrongEmail && (
                        <div className={`${styles.subscribeError}`}>
                            <Icon name='warning sign' />
                            Incorrect email address</div>
                    )}

                </div>

            </div>
        )
    }

}