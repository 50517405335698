import React, {Component} from 'react'
import {Button} from "semantic-ui-react";
import {HashLink as Link} from "react-router-hash-link";
import Tracker from "../../trackUtils";

import styles from './HardenSection.module.scss';

export default class HardenSection extends Component {

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    render() {


        return (
            <div className={`${styles.sectionWrapper}`}>

                <div className={`${styles.sectionContent}`}>

                    <div className={`${styles.sectionHeader}`}>
                        Don't wait till companies {/* <br/> */} harden privacy protection.{/* <br/> */} Do it yourself. Today.
                    </div>

                    <div
                        data-aos="fade-up"
                        data-aos-delay="500"
                        data-aos-duration="600">
                    <div className={`${styles.description}`}>
                        
                        <div>
                            Each virtual persona is 
                            <span className={`${styles.highlight}`}>unique and has fully functional</span>
                            &nbsp;
                            <br className={`${styles.tabletOnly2}`}/>
                            <div className={`${styles.email}`}>email,</div>
                            &nbsp;<div className={`${styles.login}`}>login,</div>
                            &nbsp;<div className={`${styles.password}`}>password,</div>
                            &nbsp;<div className={`${styles.name}`}>name.</div>
                            
                            <br className='mobile-only'/>
                        </div>

                        <div>
                            You can dynamically generate&nbsp;
                            <div className={`${styles.phone}`}>phone numbers</div>&nbsp;and&nbsp;
                            <div className={`${styles.card}`}>payment cards.</div>
                        </div>
                    </div>

                    <div className={`${styles.important}`}>

                        <div className={`${styles.importantHeader}`}>
                            Identities are not-linkable to the owner or each other.
                        </div>

                        <div className={`${styles.importantDescription}`}>
                            Online services are hit with 100M+ Credentials Stuffing attacks daily.<br/>
                            With no.ID, if a data breach happens at any external services - you're safe.
                        </div>

                    </div>

                    
                    </div>
                    
                </div>

            </div>
        )
    }
}