import React, { Component, useEffect, useLayoutEffect } from 'react'
import './UnderConstructionPage.scss'
import { Button, Grid, Icon, Image, Input } from "semantic-ui-react";
import MenuTop from '../MenuTop/MenuTop';
import FooterSection from '../FooterSection/FooterSection';
import SubscriptionForm from '../SubscriptionForm/SubscriptionForm';
import { TimerComponent } from '../TimerComponent/TimerComponent';


const backgroundPolygons = () => (
    <>
        <svg id="svg"
            width="90%"
            height="100%"
            // viewBox="0 0 1271 645"
            // viewBox="0 0 1271 605"
            viewBox="0 0 1271 725"
            data-aos="slide-up"
            // data-aos-easing='linear'
            data-aos-delay="500"
            data-aos-duration="600"
        >
            <g transform="translate(-110.000000, -48.000000)"
                // fill="#FDFAF4"
                fill="#FDFAF4"
            >
                <g transform="translate(58.000000, -20.000000)">
                    <polygon points="52.3125 68.25 1323 28 1208.63281 573.765625 155.171875 673" />
                </g>
            </g>
        </svg>

        <svg id="svg-tablet" width="100%"
            // viewBox="0 0 768 645"
            viewBox="50 -10 768 645"
            data-aos="slide-up"
            // data-aos-easing='linear'
            data-aos-delay="00"
            data-aos-duration="600"
        >
            <g transform="translate(-130.000000, -48.000000)"
                // fill="#FDFAF4"
                fill="#FDFAF4"

            >
                <g transform="translate(58.000000, -20.000000)">
                    <polygon points="52.3125 68.25 1323 28 1208.63281 573.765625 155.171875 673" />
                </g>
            </g>
        </svg>

        <svg id="svg-mobile" width="100%" viewBox="0 0 398 342"
            data-aos="slide-up"
            // data-aos-easing='linear'
            data-aos-delay="000"
            data-aos-duration="600">
            <g transform="translate(-16.000000, 0.000000)"
                fill="#FDFAF4"
                // fill="#225364"
            >
                <polygon id="yellow-m-bg" points="16 9.15310078 427 -13 390.008282 287.382631 49.2695514 342" />
            </g>
        </svg>

        <svg id="svg2" width="100%" viewBox="0 0 1361 636"
            data-aos="slide-down"
            data-aos-delay="500"
            data-aos-duration="600"
        // data-aos-easing='linear'
        >
            <g transform="translate(0, -12.000000)"
                // fill="#EDF8F8"
                fill="#EDF8F8"
            >
                <polygon id="blue-bg" points="0 60 1361 0 1246.63281 545.765625 300.855469 636"></polygon>
            </g>
        </svg>

        <svg id="svg2-tablet" width="100%" viewBox="80 50 768 636"
            data-aos="slide-down"
            data-aos-delay="000"
            data-aos-duration="600"
        >
            <g transform="translate(0, -12.000000)"
                // fill="#EDF8F8"
                fill="#EDF8F8"
            >
                <polygon id="blue-bg" points="0 60 1361 0 1246.63281 545.765625 300.855469 636"></polygon>
            </g>
        </svg>


        <svg id="svg2-mobile" width="100%" viewBox="0 0 390 301"
            data-aos="slide-down"
            data-aos-delay="000"
            data-aos-duration="600">
            <g transform="translate(-8.000000, 0.000000)"
                fill="#EDF8F8"
                // fill="#0e3b49"
            >
                <polygon id="blue-m-bg" points="20 -15.9811321 460 -49 423.026038 251.342718 117.264075 301" />
            </g>
        </svg>
    </>
);

export default function UnderConstructionPage({history}) {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <div className="content-wrapper">

                <div className='section-wrapper under-construction-section'>

                    {backgroundPolygons()}

                    <div className='section-content under-construction-section'>

                        <MenuTop />

                        <div className='under-construction-section-image-content mobile-only'>

                            <Image src='/images/01_social_media_X_min.png' />

                        </div>

                        <div className='grid'>

                            <div className='padding-col' />

                            <div className='content-col'
                                data-aos="fade-up"
                                data-aos-delay="500"
                                data-aos-duration="600"
                            >

                                <div className='under-construction-section-content-wrapper'>

                                    <div className='under-construction-section-header'>
                                        Our new website
                                        <div>is on the way.</div>
                                    </div>

                                    <div className='under-construction-section-subheader'>
                                        Be the first to know when we launch.
                                    </div>


                                    <TimerComponent />

                                    <SubscriptionForm history={history}/>

                                </div>

                            </div>

                            <div className='content-col image-col'
                                data-aos="zoom-out"
                                data-aos-delay="500"
                                data-aos-duration="600"
                            // data-aos-easing='ease-in'
                            >
                                <div className='under-construction-section-image-content'>
                                    <Image src='/images/kv@2x.png' />
                                </div>
                            </div>

                            <div className='content-col image-col-tablet'>

                                <div className='under-construction-section-image-content'>
                                    {/*<Image src='/images/mask-identity.png' />*/}
                                    <Image src='/images/01_social_media_X_min.png' />

                                    <div className='image-mask' />
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            
            <div className="under-construction-footer-wrapper">
                <FooterSection history={history} />
            </div>
            
            </div>
        </>
    )
}