/*global fathom*/

const eventGoals = {
    'menu-how-it-works' : 'E5LTPX88',
    'menu-benefits' : 'IXMBWOLC',
    'menu-how-to-use' : 'TLLKCRHY',
    'menu-protection' : 'O9MCZFG6',
    'menu-is-it-for-me' : 'E0XRX0IK',
    'learn-more' : 'NF2LQ8MJ',
    'create-first-identity': 'QZ5IFFSL',
    'demo-protect-your-data' : '9KT8GHUM',
    'game-changer-protect-your-data' : 'OUALZMED',
    'subscribe' : 'UG8GMPNA',
    'subscribe-success': 'KU9RUBQG',
    'subscribe-failure' : 'ZTNXRXWF',
    'subscribe-failure-already-subscribed' : 'BG7I4LHU',
    'subscribe-failure-mailchimp-error' : 'SXZFSHKK',
    'footer-whitepaper': 'U6RB4D5V',
    'footer-blog': 'JNJSADWB',
    'footer-case-study': 'FSLAWKJF',
    'footer-about-us': 'PEXU9DPM',
    'footer-contact': 'K1OBQR6Z',
    'footer-careers': 'UCRLGJNO',
    'footer-pricing': 'R5FVUTLI',
    'footer-demo': 'UVWOGAKB',
    'footer-terms-of-service': '2KH9BHAL',
    'footer-privacy-policy': 'IMCCJL6C',
    'scroll-0' : 'ELCWSFVT',
    'scroll-15' : 'SHEFQHJM',
    'scroll-30' : '1QMCWCXN',
    'scroll-50' : '5GDX8EMC',
    'scroll-75' : 'HYZVO8BY',
    'scroll-90' : '4FPBHNEP',
}

export default class Tracker {

    static trackGoal(name) {
        try {
            console.log("Tracking " + name + " goal: " + eventGoals[name]);
            fathom.trackGoal(eventGoals[name], 0);
        } catch(e) {
            //do nothing
        }
    }

}