import React, {useEffect, useState} from 'react'
import {Button} from "semantic-ui-react";
import {HashLink as Link} from "react-router-hash-link";
import Tracker from "../../trackUtils";
import _ from "lodash"

import styles from "./OneKeySection.module.scss"

const TYPES = [

    {
        name: "Daily Mask",
        header: "Works with 93% of online services and applications.",
        description: "Best for daily online activities or handling confidential interactions like mental health groups or substance abuse discussions.",
        image_url: "/images/first_type_card.svg",
        is_anonymous: true
    }, 

    {
        name: "Chatty Mask",
        header: "No more post-click sale calls.",
        description: "Perfect when you want to share your contact details for a limited time  (e.g., business deals, online auctions or dating platforms).",
        image_url: "/images/second_type_card.svg",
        is_anonymous: true
    }, 

    {
        name: "Shopping Mask",
        header: "Secure shopping with privacy everywhere.",
        description: "Choose this identity to safely shop or create dedicated means of payment for recurring subscriptions.",
        image_url: "/images/third_type_card.svg",
        is_anonymous: false
    }, 

]

const EXTRA_CLASS = [
    `${styles.first}`,
    `${styles.second}`,
    `${styles.third}`,
]

export default function OneKeySection() {

    const [active, setActive] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
          setActive(active => (active + 1) % 3)
        }, 4000);
        return () => clearInterval(interval);
      }, []);

    return (
        <div id="one-key-section" className={`${styles.sectionWrapper}`}>

            <div className={`${styles.content}`}>

                <div className={styles.header}>
                    One key&#8212;unlimited secure identities.
                </div>

                <div className={styles.subheader}>
                    Create an anonymous, single-purpose persona for each service account,
                    shopping experience and any situation when you want to protect your real identity.
                </div>

                <div className={styles.identityTypes}>

                    <div className={styles.typeHeaders}>
                        {_.map(TYPES, (type, idx) => (
                            <div className={`${styles.typeHeaderWrapper} ${active == idx ? styles.active : ''}`}
                                 onClick={() => setActive(idx)}>

                                <div className={styles.typeHeader}>
                                    <div className={styles.typeHeaderLabel}>
                                        {type.name}
                                    </div>
                                </div>
                                <div className={styles.typeHeaderArrow}/>
                            </div>
                        ))}
                    </div>

                </div>

                <div className={styles.identitiesWrapper}>

                    <div className={styles.identities}>

                        {_.map(TYPES, (type, idx) => (
                            <div className={`${styles.identity} ${EXTRA_CLASS[idx]} ${active == idx ? '' : styles.invisible}`}>
                                <img src={type.image_url}/>
                                <div className={styles.description}>
                                    <div className={styles.label}>100% {type.is_anonymous ? 'anonymous' : 'private'}</div>
                                    <div className={styles.header1}>{type.header}</div>
                                    <div className={styles.header2}>{type.description}</div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                
                <Link smooth to="#join" onClick={() => { Tracker.trackGoal('create-first-identity')}}>
                    <Button className={styles.ctaButton}
                            // data-aos="fade-up"
                            // data-aos-delay="500"
                            // data-aos-duration="600"
                    >
                        Create My First Identity
                    </Button>
                </Link>

            </div>

        </div>
    )
}