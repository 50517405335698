import React, { Component, useState } from 'react';
import "./TimerComponent.scss";
import moment from 'moment';
import { useInterval } from 'usehooks-ts';


const TimeFigure = ({count, label}) => (
    <div className="time-square-wrapper">
        <div className="time-square">
            {count}
        </div>
        <div className="time-square-label">
            {label}
        </div>
    </div>
)

const finalDate = moment('2022-12-27T23:00:00');

export const TimerComponent = () => {

    const [days, setDays] = useState();
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();


    useInterval(() => {
        const duration = moment.duration(finalDate.diff(moment.now()));
        setDays(duration.days() + duration.months() * 30)
        setHours(duration.hours());
        setMinutes(duration.minutes());
        setSeconds(duration.seconds());
    }, 1000);

    return(
        <div className="timer-wrapper">
        
            <TimeFigure count={days} label="Days"/>
            <TimeFigure count={hours} label="Hours"/>
            <TimeFigure count={minutes} label="Minutes"/>
            <TimeFigure count={seconds} label="Seconds"/>

        </div>
    )

}