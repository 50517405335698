import React, {Component} from 'react'
import {Button, Image} from 'semantic-ui-react'
import {HashLink as Link} from 'react-router-hash-link';
import './MainSection.scss'
import Tracker from "../../trackUtils";
import MenuTop from "../MenuTop/MenuTop";

export default class MainSection extends Component {

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    render() {


        return (
            <div className='section-wrapper main-section' onScroll={this.onScroll}>

                <svg id="svg"
                     width="90%"
                     height="100%"
                     // viewBox="0 0 1271 645"
                     viewBox="0 0 1271 605"
                     data-aos="slide-up"
                     // data-aos-easing='linear'
                     data-aos-delay="500"
                     data-aos-duration="600"
                >
                    <g transform="translate(-110.000000, -48.000000)"
                       // fill="#FDFAF4"
                       fill="#FDFAF4"
                    >
                        <g transform="translate(58.000000, -20.000000)">
                            <polygon points="52.3125 68.25 1323 28 1208.63281 573.765625 155.171875 673"/>
                        </g>
                    </g>
                </svg>

                <svg id="svg-tablet" width="100%"
                     // viewBox="0 0 768 645"
                     viewBox="50 -10 768 645"
                     data-aos="slide-up"
                    // data-aos-easing='linear'
                     data-aos-delay="00"
                     data-aos-duration="600"
                >
                    <g transform="translate(-130.000000, -48.000000)"
                       // fill="#FDFAF4"
                       fill="#FDFAF4"

                    >
                        <g transform="translate(58.000000, -20.000000)">
                            <polygon points="52.3125 68.25 1323 28 1208.63281 573.765625 155.171875 673"/>
                        </g>
                    </g>
                </svg>

                <svg id="svg-mobile" width="100%" viewBox="0 0 398 342"
                     data-aos="slide-up"
                    // data-aos-easing='linear'
                     data-aos-delay="000"
                     data-aos-duration="600">
                    <g transform="translate(-16.000000, 0.000000)"
                       fill="#FDFAF4"
                    //    fill="#225364"
                    >
                        <polygon id="yellow-m-bg" points="16 9.15310078 427 -13 390.008282 287.382631 49.2695514 342"/>
                    </g>
                </svg>

                <svg id="svg2" width="100%" viewBox="0 0 1361 636"
                     data-aos="slide-down"
                     data-aos-delay="500"
                     data-aos-duration="600"
                     // data-aos-easing='linear'
                >
                    <g transform="translate(0, -12.000000)"
                       // fill="#EDF8F8"
                       fill="#EDF8F8"
                    >
                        <polygon id="blue-bg" points="0 60 1361 0 1246.63281 545.765625 300.855469 636"></polygon>
                    </g>
                </svg>

                <svg id="svg2-tablet" width="100%" viewBox="80 50 768 636"
                     data-aos="slide-down"
                     data-aos-delay="000"
                     data-aos-duration="600"
                >
                    <g transform="translate(0, -12.000000)"
                       // fill="#EDF8F8"
                       fill="#EDF8F8"
                    >
                        <polygon id="blue-bg" points="0 60 1361 0 1246.63281 545.765625 300.855469 636"></polygon>
                    </g>
                </svg>


                <svg id="svg2-mobile" width="100%" viewBox="0 0 390 301"
                     data-aos="slide-down"
                     data-aos-delay="000"
                     data-aos-duration="600">
                    <g transform="translate(-8.000000, 0.000000)"
                       fill="#EDF8F8"
                    //    fill="#0e3b49"
                    >
                        <polygon id="blue-m-bg" points="20 -15.9811321 460 -49 423.026038 251.342718 117.264075 301"/>
                    </g>
                </svg>

                <div className='section-content main-section'>

                    <MenuTop />

                    <div className='main-section-image-content mobile-only'>

                        {/*<Image src='/images/mask-identity.png' />*/}

                        <Image src='/images/01_social_media_X_min.png' />

                        
                        {/*<div className='image-mask'/>*/}

                    </div>

                    <div className='grid'>

                        <div className='padding-col' />

                        <div className='content-col'
                             data-aos="fade-up"
                             data-aos-delay="500"
                             data-aos-duration="600"
                             // data-aos-easing='linear'
                        >

                            <div className='main-section-content-wrapper'>

                                <div className='main-section-header'>
                                Sign up, shop or use apps without revealing your data.
                                <span style={{opacity: "0.7"}}> Keep privacy and avoid data leaks.</span>
                                </div>

                                <div className='main-section-subheader'>
                                    Mask your identity. Get peace of mind.
                                </div>

                                <Link smooth to="#join" onClick={() => { Tracker.trackGoal('learn-more')}} >
                                    <Button className='main-section-cta-button'>
                                        Create My First Identity
                                    </Button>
                                </Link>

                            </div>

                        </div>

                        <div className='content-col image-col'
                             data-aos="zoom-out"
                             data-aos-delay="500"
                             data-aos-duration="600"
                             // data-aos-easing='ease-in'
                        >

                            <div className='main-section-image-content'>

                                {/*<Image src='/images/mask-identity.png' />*/}
                                {/*<Image src='/images/02_Social media.png' />*/}
                                <Image src='/images/01_social_media_X_min.png' />

                                {/* <Image src='/images/header-image.png' /> */}


                                {/*<Image src='/images/03_Social media v color.png' />*/}


                            </div>
                        </div>

                        <div className='content-col image-col-tablet'>

                            <div className='main-section-image-content'>
                                {/*<Image src='/images/mask-identity.png' />*/}
                                <Image src='/images/01_social_media_X_min.png' />

                                <div className='image-mask'/>
                            </div>
                        </div>

                        {/*<div className='padding-col' />*/}

                    </div>

                </div>

            </div>
        )
    }
}