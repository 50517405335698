import React, {Component} from 'react';
import './App.scss';
import MenuTop from "./components/MenuTop/MenuTop";
import 'semantic-ui-css/semantic.css';
import MainSection from "./components/MainSection/MainSection";
import BenefitsSection from "./components/BenefitsSection/BenefitsSection";
import DemoSection from "./components/DemoSection/DemoSection";
import GameChangerSection from "./components/GameChangerSection/GameChangerSection";
import ProtectionSection from "./components/ProtectionSection/ProtectionSection";
import UsageSection from "./components/UsageSection/UsageSection";
import SubscribeSection from "./components/SubscribeSection/SubscribeSection";
import FooterSection from "./components/FooterSection/FooterSection";
import {Redirect, Route, Switch} from 'react-router-dom'
import UnderConstructionPage from "./components/UnderConstructionPage/UnderConstructionPage";
import Tracker from "./trackUtils";
import DemoVideoPage from "./components/DemoVideoPage/DemoVideoPage";
import TestSection from "./components/TestSection/TestSection";
import '../node_modules/aos/dist/aos.css';
import AOS from 'aos';
import ContactPage from "./components/ContactPage/ContactPage";
import OneKeySection from './components/OneKeySection/OneKeySection';
import HardenSection from './components/HardenSection/HardenSection';
import ThankYouPage from './components/ThankYouPage/ThankYouPage';
export default class App extends Component {

    constructor(props) {
        super(props);

        AOS.init({
            // initialise with other settings
            // duration : 2000
        });

        this.tresholds = new Set([0, 15, 30, 50, 75, 90]);
        this.tresholdActive = 0;

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }


    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(e) {
        let h = document.documentElement,
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';

        let percent = Math.round((h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100);

        if (this.tresholds.has(percent) && this.tresholdActive !== percent) {
            Tracker.trackGoal('scroll-' + percent);
            this.tresholdActive = percent;
        }

    }

    render() {

        {/*<div className="App" onScroll={handleScroll}>*/}

        return (

            <div className="App"
                 // onScroll={this.handleScroll}
            >

                <Switch>
                    <Route path={"/test"} exact component={TestSection}/>

                    <Route path={"/whitepaper"} exact component={UnderConstructionPage}/>
                    <Route path={"/blog"} exact component={UnderConstructionPage}/>
                    <Route path={"/case-study"} exact component={UnderConstructionPage}/>
                    <Route path={"/pricing"} exact component={UnderConstructionPage}/>
                    <Route path={"/demo"} exact component={UnderConstructionPage}/>
                    <Route path={"/about-us"} exact component={UnderConstructionPage}/>
                    <Route path={"/contact"} exact component={ContactPage}/>
                    <Route path={"/careers"} exact component={UnderConstructionPage}/>
                    <Route path={"/terms-of-service"} exact component={UnderConstructionPage}/>
                    <Route path={"/privacy-policy"} exact component={UnderConstructionPage}/>

                    <Route path={"/thank-you"} exact component={ThankYouPage}/>

                    <Route path='/quiz-pl' component={() => { 
                        window.location.href = 'https://form.typeform.com/to/P26RACh8'; 
                        return null;
                    }}/>

                    <Route path={"/grant-application-video"} exact component={DemoVideoPage}/>

                    <Route path={"/"} exact>

                        <MainSection/>

                        <OneKeySection/>

                        <HardenSection/>

                        <BenefitsSection/>

                        <DemoSection/>

                        <ProtectionSection/>

                        <UsageSection/>

                        <SubscribeSection history={this.props.history}/>

                        <FooterSection history={this.props.history}/>

                    </Route>

                    <Route render={() => (<Redirect to="/"/>)}/>


                </Switch>

            </div>
        );
    }
}

// export default App;
