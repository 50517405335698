import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter, Route} from 'react-router-dom';
import { hydrate, render } from "react-dom";


// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//
//     hydrate(
//         <React.StrictMode>
//             <BrowserRouter>
//                 <Route path="/" component={App}/>
//                 {/*<App />*/}
//             </BrowserRouter>
//         </React.StrictMode>,
//         document.getElementById('root')
//     );
//
// } else {
    ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <Route path="/" component={App}/>
                {/*<App />*/}
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById('root')
    );
// }
