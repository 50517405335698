import React, {Component} from 'react'
import './DemoVideoPage.css'
import ReactPlayer from 'react-player/youtube'

export default class DemoVideoPage extends Component {

    render() {

        return (

            <div className='under-construction-wrapper'>

                <div> no.ID Demo </div>
                <ReactPlayer url='https://youtu.be/JxcsoW_ERPA' />

            </div>
        )
    }
}