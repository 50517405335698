import React, {Component} from 'react'
import './ContactPage.css'

export default class ContactPage extends Component {

    render() {

        return (

            <div className='under-construction-wrapper'>

                <div style={{fontSize: '16px'}}>Company: <strong style={{fontSize: '16px'}}>No.ID Ehf</strong></div>
                <div>Address: <strong style={{fontSize: '16px'}}>Storhofda 33, 110 Reykjavik Iceland</strong></div>
                <div>Email: <strong style={{fontSize: '16px'}}>hello@noid.today</strong></div>

            </div>
        )
    }
}