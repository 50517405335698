import React, {Component} from 'react'
import './SubscribeSection.scss'
import $ from 'jquery';
import {Button, Input, Icon} from "semantic-ui-react";
import Tracker from "../../trackUtils";
import * as EmailValidator from 'email-validator';
import {PulseLoader} from "react-spinners";

const ACTIVE_CAMPAIGN_URL = "https://noid.activehosted.com/proc.php?";

export default class SubscribeSection extends Component {

    constructor(props) {

        super(props);

        this.state = {
            invalidEmail : false,
            email : '',

            showSuccessMsg: false,
            showError: false,
            inProgress: false,
            showErrorWrongEmail: false,

            checked: false,
            aggreementError: false
        }

        this.handleSubscribeClick = this.handleSubscribeClick.bind(this);
        this.onError = this.onError.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }

    onValueChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    handleCheckChange() {
        this.setState(prevState => ({
            checked: !prevState.checked
        }));
    }

    subscribe() {


    //   const contact = {
    //     contact: {
    //             email: this.state.email,
    //             firstName: "",
    //             lastName: "",
    //             phone: ""
    //         }
    //     };

        // const data = new FormData();
        // data.append("email", this.state.email);
        
        // const options = {
        //     method: 'POST',
        //     mode: "no-cors",
        //     body: data
        // };

        // const _ = this;
        // fetch(ACTIVE_CAMPAIGN_URL, options)
        //     .then((response) => {
        //         console.log("Response: " + JSON.stringify(response, null, 3));
        //         _.onSuccess();
        //     })
        //     .catch(() => {
        //         _.onError();
        //     })

        const body = {
            email : this.state.email
        }
        const _ = this;

        $.ajax({
            url: 'https://noid.activehosted.com/proc.php?u=1&f=1&s=&c=0&m=0&act=sub&v=2&or=a417cd589db826ed64c7f95cc86df7a8',
            type: 'GET',
            data: body,
            crossDomain: true,
            cache: false,
            dataType: 'jsonp',
            // contentType: "application/json; charset=utf-8",
            async: true,
            success: function (data) {
                // console.log("Inside response");
                console.log("data: " + JSON.stringify(data));
                if (data['result'] != "success") {
                    //ERROR
                    _.onError(data['msg'])
                } else {
                    //SUCCESS - Do what you like here
                    _.onError();
                }
            },
            error: function(xhr, status, err) {
                _.onSuccess();
                // _.onError();
                // console.log("xhr : " + JSON.stringify(xhr, null, 3));
                // console.log("status : " + JSON.stringify(status, null, 3));
                // console.log("ERROR : " + JSON.stringify(err));

                // var json = JSON.parse(xhr.responseText);
                // console.log("JSON : " + JSON.stringify(json));
            }
        });
        
        // https://noid.activehosted.com/proc.php?u=1&f=1&s=&c=0&m=0&act=sub&v=2&or=a417cd589db826ed64c7f95cc86df7a8&fullname=asdf&email=maciek+test3@noid.today&jsonp=true
        
        //     https://noid.activehosted.com/f/1



        // if (2+2 == 4) {
        //     return;
        // }

        // const body = {
        //     EMAIL : this.state.email
        // }

        // const _ = this;

        // $.ajax({
        //     url: 'https://today.us10.list-manage.com/subscribe/post-json?u=94942bfb14ef1d03b27f7a15f&id=29f0893a2f&c=?',
        //     type: 'GET',
        //     data: body,
        //     cache: false,
        //     dataType: 'jsonp',
        //     contentType: "application/json; charset=utf-8",
        //     async: true,
        //     success: function (data) {
        //         // console.log("Inside response");
        //         // console.log("data: " + JSON.stringify(data));
        //         if (data['result'] != "success") {
        //             //ERROR
        //             _.onError(data['msg'])
        //         } else {
        //             //SUCCESS - Do what you like here
        //             _.onError();
        //         }
        //     },
        //     error: function(err) {
        //         // _.onSuccess();
        //         _.onError();
        //         // console.log("ERROR : " + JSON.stringify(err));
        //     }
        // });
    
    }

    handleSubscribeClick(e) {
        e.preventDefault();

        this.setState({
            aggreementError: false,
            showErrorWrongEmail: false
        });

        if (this.state.inProgress) {
            return;
        }

        const email = this.state.email;
        if (!EmailValidator.validate(email)) {
            console.log("Wrong email");
            this.setState({
                inProgress : false,
                showErrorWrongEmail: true
            });
            return;
        }

        if (!this.state.checked) {
            this.setState({aggreementError: true});
            return;
        }

        Tracker.trackGoal('subscribe');

        this.setState({
            inProgress : true,
            showError: false,
            showSuccessMsg: false,
            showErrorWrongEmail: false,
            aggreementError: false
        })

        this.subscribe();
    }

    onSuccess() {
        Tracker.trackGoal('subscribe-success');
        this.setState({
            inProgress : false,
            showError: false,
            showErrorWrongEmail: false,
            showSuccessMsg: true
        });
        this.props.history.push(`/thank-you`);
    }

    onError(errorMessage) {

        if (errorMessage) {
            if (errorMessage.includes('already subscribed')) {
                Tracker.trackGoal('subscribe-failure-already-subscribed');
            } else {
                Tracker.trackGoal('subscribe-failure-mailchimp-error');
            }
        } else {
            Tracker.trackGoal('subscribe-failure');
        }

        this.setState({
            inProgress : false,
            showError: true,
            showErrorWrongEmail: false,
            showSuccessMsg: false
        });
    }

    render() {

        return (

            <div id="join" className='section-wrapper subscribe-section'>

                <div className='section-content subscribe-section'>

                    <div className='subscribe-header'>
                        Our service is on the way!
                    </div>

                    {/*<div className='subscribe-subheader'>*/}
                    {/*    no.ID is in closed Beta.*/}
                    {/*    Become one of the first <br className='desktop-only' /><br className='tablet-only' />*/}
                    {/*    users and enroll on a waiting list.*/}
                    {/*</div>*/}

                    <div className='subscribe-subheader'>
                        no.ID is currently in closed beta. Sign-up for the waitlist<br/> 
                        to become one of the first users.
                    </div>

                    <div className='subscribe-section-form'>

                        <Input
                            className='subscribe-input'
                            disabled={this.state.inProgress}
                            name='email' placeholder='Enter your email address' value={this.state.email}
                            onChange={this.onValueChange}
                        />

                        <Button
                            className='subscribe-section-cta-button'
                            disable={this.state.inProgress}
                            onClick={this.handleSubscribeClick}>

                            { !this.state.inProgress && ("Join waitlist" )}
                            { this.state.inProgress && (
                                <PulseLoader
                                    size={10}
                                    color={"#ffffff"}
                                    loading={true}
                                />
                            )}

                        </Button>

                    </div>

                    <div className='subscribe-section-form'>
                        <div className={`agreement-wrapper ${this.state.aggreementError ? 'error': ''}`}>
                                <input type="checkbox" disabled={this.state.inProgress}
                                        checked={this.state.checked} onChange={this.handleCheckChange} />
                                <div>Yes, sign me up. I agree to the privacy policy terms</div>
                        </div>
                    </div>

                    
                    <div className='subscribe-output-wrapper'>
                        { this.state.showSuccessMsg && (
                            <div className='subscribe-success'>
                                <Icon name='check circle'/>
                                Great! Now please wait for your invitation.</div>
                        )}

                        { this.state.showError && (
                            <div className='subscribe-error'>
                                <Icon name='warning sign'/>
                                Problem occurred. Please try again</div>
                        )}

                        { this.state.showErrorWrongEmail && (
                            <div className='subscribe-error'>
                                <Icon name='warning sign'/>
                                Incorrect email address</div>
                        )}

                    </div>

                    <div className='subscribe-info'>
                        {/*We'll contact you once - to notify about your access.*/}
                        We’ll send you an email to let you know when the service launches.
                        <br className='desktop-only' />
                        <br className='tablet-only' />
                        <strong> Afterwards, the email will be deleted.</strong>
                    </div>

                    {/*<div className='subscribe-section-form'>*/}

                    {/*    /!*<Image className='subscribe-section-img' src='/images/email-notification.png'/>*!/*/}

                    {/*    { !this.state.showSuccessMsg && (*/}
                    {/*        <div>*/}
                    {/*            <Input*/}
                    {/*                disabled={this.state.inProgress}*/}
                    {/*                name='email' placeholder='Your Email' value={this.state.email}*/}
                    {/*                onChange={this.onValueChange}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    )}*/}

                    {/*    /!*<div className='game-changer-section-cta-wrapper'>*!/*/}

                    {/*    { !this.state.showSuccessMsg && (*/}

                    {/*<Button*/}
                    {/*    className='subscribe-section-cta-button'*/}
                    {/*    disable={this.state.inProgress}*/}
                    {/*    onClick={this.handleSubscribeClick}*/}
                    {/*        >*/}
                    {/*            { !this.state.inProgress && ("Add Me to Waiting List" )}*/}
                    {/*            { this.state.inProgress && (*/}
                    {/*                <PulseLoader*/}
                    {/*                    size={10}*/}
                    {/*                    color={"#ffffff"}*/}
                    {/*                    loading={true}*/}
                    {/*                />*/}
                    {/*            )}*/}
                    {/*        </Button>*/}

                    {/*    )}*/}

                    {/*    { this.state.showSuccessMsg && (*/}
                    {/*        <div className='subscribe-success'>*/}
                    {/*            <Icon name='check circle'/>*/}
                    {/*            Great! <br className="mobile-only"/> Now please wait for your invitation.</div>*/}
                    {/*    )}*/}

                    {/*    { this.state.showError && (*/}
                    {/*        <div className='subscribe-error'>*/}
                    {/*            <Icon name='warning sign'/>*/}
                    {/*            Problem occurred. Please try again</div>*/}
                    {/*    )}*/}

                    {/*    { this.state.showErrorWrongEmail && (*/}
                    {/*        <div className='subscribe-error'>*/}
                    {/*            <Icon name='warning sign'/>*/}
                    {/*            Incorrect email address</div>*/}
                    {/*    )}*/}

                    {/*    <div className='info-email'>*/}
                    {/*        We'll contact you once - to notify about your access. <br />*/}
                    {/*        <span>Afterwards this email will be deleted.</span>*/}
                    {/*    </div>*/}

                    {/*</div>*/}

                </div>

                <svg id='svg-subscribe' width="100%" viewBox="0 0 1361 636">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon id="path-1" fill="#EDF8F8" points="0 60 1361 0 1246.63281 545.765625 300.855469 636"/>
                    </g>

                    <foreignObject x="940" y="357">
                        <img src='/images/subscribe-envelop.png'/>
                    </foreignObject>

                </svg>

                <svg id='svg-subscribe-tablet' height="636px" viewBox="300 40 761 566">
                    <g>
                        <polygon id="path-1" fill="#EDF8F8" points="0 60 1361 0 1246.63281 545.765625 300.855469 636"/>
                    </g>

                    <foreignObject x="790" y="459">
                        <img style={{height: '128px'}} src='/images/subscribe-envelop.png'/>
                    </foreignObject>

                </svg>

                <svg id='svg-subscribe-mobile' height="684px" viewBox="450 250 350 16">

                    <g transform="rotate(-13)">
                        <polygon id="path-1" fill="#EDF8F8" points="0 60 1361 0 1246.63281 545.765625 300.855469 636"/>

                        <foreignObject x="790" y="459">
                            <img style={{height: '128px'}} src='/images/subscribe-envelop.png'/>
                        </foreignObject>

                    </g>

                </svg>

            </div>
        )
    }
}