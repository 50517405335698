import React, {Component} from 'react'

import './TestSection.scss'

export default class TestSection extends Component {

    constructor(props) {
        super(props);
    }

    render() {


        return (
                <div className='wrapper'>

                    <div className='obj'>
                        STICKY2
                    </div>

                </div>

        )
    }
}