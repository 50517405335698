import React, {Component} from 'react'
import './DemoSection.scss'
import {Button, Image} from "semantic-ui-react";
import {HashLink as Link} from "react-router-hash-link";
import Tracker from "../../trackUtils";
import {isMobileOnly} from "react-device-detect";


export default class DemoSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSticky: true
        }

        this.gameChangerDescriptionsRef = React.createRef();
        this.sectionRef = React.createRef();
        this.sectionDemoRef = React.createRef();


        this.handleScroll = this.handleScroll.bind(this);
        // this.handleGameChangerDescriptionsScroll = this.handleGameChangerDescriptionsScroll.bind(this);

    }

    componentDidMount() {
        if (isMobileOnly) {
            window.addEventListener('scroll', this.handleScroll);
            // this.gameChangerDescriptionsRef.current.addEventListener('scroll', this.handleGameChangerDescriptionsScroll)\
        }
    }

    componentWillUnmount() {
        if (isMobileOnly) {
            window.removeEventListener('scroll', this.handleScroll);
            // this.gameChangerDescriptionsRef.current.removeEventListener('scroll', this.handleGameChangerDescriptionsScroll);
        }
    }

    // handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    // handleGameChangerDescriptionsScroll() {
    //     console.log("onScroll3");
    //     console.log("BoundingRect: " + JSON.stringify(this.gameChangerDescriptionsRef.current.getBoundingClientRect(), null, 3));
    // }

    handleScroll(e) {

        let sectionRect = this.sectionRef.current.getBoundingClientRect();
        console.log("SectionRect: " + JSON.stringify(sectionRect, null, 3));

        // let offsetTop = sectionRect.top;
        // console.log("offsetTop: " + offsetTop);

        // if (offsetTop <= 0) {
        //     this.setState({
        //         isSticky: true,
        //         sectionTopScrollBase: 0
        //     });
        // }

        // if (this.state.isSticky) {

        // console.log("base: " + this.state.sectionTopScrollBase + " top: " + sectionRect.top);

        let distance = 0 - sectionRect.top;
        console.log("Setting distance : " + distance);
        this.gameChangerDescriptionsRef.current.scrollLeft = distance;

        // if (distance > this.gameChangerDescriptionsRef.current.width) {
        //     console.log("Setting sticky to false : " + distance);
        //     this.setState({ isSticky: false })
        // }
        // }

    }

    render() {

        return (

            <div id='how-to-use'>

                <div className='section-wrapper demo-section'>

                    <div className='section-content demo-section'>

                        <div className='demo-header'
                             data-aos="fade-up"
                             data-aos-delay="500"
                             data-aos-duration="600">
                            Being safe is easier<br/>
                            than you think !
                        </div>

                        <div className='demo-video'
                             data-aos="zoom-in"
                             data-aos-delay="500"
                             data-aos-duration="600">

                            <video autoPlay muted loop style={{width: '100%', height: '100%'}}>
                                <source src="/video/demo.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>

                        </div>

                        <div className='demo-steps'>

                            <div className='demo-step'
                                 data-aos="fade-up"
                                 data-aos-delay="300"
                                 data-aos-duration="600">
                                <div className='step-header'>Step 1</div>
                                <div className='step-description'>Generate<br className='tablet-only'/> a secure ID <br className='mobile-only'/> with one click</div>
                            </div>

                            <div className='demo-step'
                                 data-aos="fade-up"
                                 data-aos-delay="500"
                                 data-aos-duration="600">
                                <div className='step-header'>Step 2</div>
                                <div className='step-description'>Call, pay <br className='tablet-only'/> and receive <br className='desktop-only'/> emails <br className='tablet-only'/>while keeping privacy</div>
                            </div>

                            <div className='demo-step'
                                 data-aos="fade-up"
                                 data-aos-delay="900"
                                 data-aos-duration="600">
                                <div className='step-header'>Step 3</div>
                                <div className='step-description'>Know when your identity<br/> is compromised or attacked.</div>
                            </div>

                            <div className='demo-path-wrapper'
                                 data-aos="fade-up"
                                 data-aos-delay="100"
                                 data-aos-duration="600">

                                <svg id="svg-demo-path" height="18px" viewBox="0 0 705 18">
                                    <g id="svg-path" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.4" stroke-dasharray="4" stroke-linecap="round" stroke-linejoin="bevel">
                                        <g transform="translate(-350.000000, -3550.000000)" stroke="#EDF8F8">
                                            <g transform="translate(0.000000, 2847.000000)">
                                                <g transform="translate(260.000000, 665.000000)">
                                                    <path d="M90.0039062,68 C178.869378,66.8724226 267.101993,56.7321875 355.957031,53.8671875 C438.944815,51.1913685 536.1292,57.8357332 627.5,58 C686.462454,58.106003 740.962163,51.0455469 794.5,52"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>

                                    <g id="svg-path-arrow-1" transform="translate(-89.5, -38.59)" fill="#EDF8F8">
                                        <path d="M290.162448,51.1999652 L297.800555,56.5172414 C298.066482,56.7838616 298.066482,57.2161384 297.800555,57.4827586 L290.162448,62.8000348 C289.896522,63.0666551 289.465371,63.0666551 289.199445,62.8000348 C289.071742,62.6719993 289,62.4983458 289,62.3172762 L289,51.6827238 C289,51.3056659 289.30487,51 289.680946,51 C289.861545,51 290.034746,51.0719296 290.162448,51.1999652 Z" id="Rectangle"></path>
                                    </g>

                                    <g id="svg-path-arrow-1" transform="translate(199.5, -37.59)" fill="#EDF8F8">
                                        <path d="M290.162448,51.1999652 L297.800555,56.5172414 C298.066482,56.7838616 298.066482,57.2161384 297.800555,57.4827586 L290.162448,62.8000348 C289.896522,63.0666551 289.465371,63.0666551 289.199445,62.8000348 C289.071742,62.6719993 289,62.4983458 289,62.3172762 L289,51.6827238 C289,51.3056659 289.30487,51 289.680946,51 C289.861545,51 290.034746,51.0719296 290.162448,51.1999652 Z" id="Rectangle"></path>
                                    </g>

                                </svg>

                                {/*<svg id="svg-demo-path-mobile" height="18px" viewBox="0 0 705 18">*/}
                                {/*    <g transform="rotate(90)">*/}
                                {/*        <g id="svg-path" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.4" stroke-dasharray="4" stroke-linecap="round" stroke-linejoin="bevel">*/}
                                {/*            <g transform="translate(-350.000000, -3550.000000)" stroke="#EDF8F8">*/}
                                {/*                <g transform="translate(0.000000, 2847.000000)">*/}
                                {/*                    <g transform="translate(260.000000, 665.000000) ">*/}
                                {/*                        <path d="M90.0039062,68 C178.869378,66.8724226 267.101993,56.7321875 355.957031,53.8671875 C438.944815,51.1913685 536.1292,57.8357332 627.5,58 C686.462454,58.106003 740.962163,51.0455469 794.5,52"></path>*/}
                                {/*                    </g>*/}
                                {/*                </g>*/}
                                {/*            </g>*/}
                                {/*        </g>*/}

                                {/*        <g id="svg-path-arrow-1" transform="translate(-89.5, -38.59)" fill="#EDF8F8">*/}
                                {/*            <path d="M290.162448,51.1999652 L297.800555,56.5172414 C298.066482,56.7838616 298.066482,57.2161384 297.800555,57.4827586 L290.162448,62.8000348 C289.896522,63.0666551 289.465371,63.0666551 289.199445,62.8000348 C289.071742,62.6719993 289,62.4983458 289,62.3172762 L289,51.6827238 C289,51.3056659 289.30487,51 289.680946,51 C289.861545,51 290.034746,51.0719296 290.162448,51.1999652 Z" id="Rectangle"></path>*/}
                                {/*        </g>*/}

                                {/*        <g id="svg-path-arrow-1" transform="translate(199.5, -37.59)" fill="#EDF8F8">*/}
                                {/*            <path d="M290.162448,51.1999652 L297.800555,56.5172414 C298.066482,56.7838616 298.066482,57.2161384 297.800555,57.4827586 L290.162448,62.8000348 C289.896522,63.0666551 289.465371,63.0666551 289.199445,62.8000348 C289.071742,62.6719993 289,62.4983458 289,62.3172762 L289,51.6827238 C289,51.3056659 289.30487,51 289.680946,51 C289.861545,51 290.034746,51.0719296 290.162448,51.1999652 Z" id="Rectangle"></path>*/}
                                {/*        </g>*/}
                                {/*    </g>*/}

                                {/*</svg>*/}

                            </div>

                        </div>

                        <div className='demo-cta-wrapper'
                             data-aos="fade-up"
                             data-aos-delay="600"
                             data-aos-duration="600">
                            <Link smooth to="#join" onClick={() => { Tracker.trackGoal('demo-protect-your-data')}}>
                                <Button className='demo-cta-button'>
                                    Protect Your Data
                                </Button>
                            </Link>
                        </div>

                    </div>

                </div>

                <div className='section-wrapper game-changer-section'>

                    <div className='section-content game-changer-section' >

                        <div className='game-changer-header'
                             data-aos="fade-up"
                             data-aos-delay="300"
                             data-aos-duration="600"
                        >
                            The real game changer. <br/>
                            Your privacy on a whole new level.
                        </div>

                        <div className={`game-changer-descriptions-wrapper`} ref={this.sectionRef}>

                            <div className={`game-changer-descriptions-content ${this.state.isSticky ? 'sticky' : ''}`}>

                                <div className='game-changer-descriptions-content-scroll' ref={this.gameChangerDescriptionsRef}>
                                    <Image className='game-changer-img' src='/images/3-areas-of-privacy-protection.png'
                                           data-aos="zoom-out-up"
                                           data-aos-delay="300"
                                           data-aos-duration="600"
                                    />
                                    <div className='game-changer-descriptions'>

                                        <div className='game-changer-desc'
                                            // data-aos="fade-up"
                                            // data-aos-delay="100"
                                            // data-aos-duration="600"
                                        >
                                            <div className='desc-header'>Websites</div>
                                            <div className='desc-text'>
                                                Control cookies
                                                and ad tracking 
                                                in your browser.
                                            </div>

                                            <a href='https://brave.com' target="_blank">
                                                <div className='desc-recommend'>
                                                    <div>Recommended:</div>
                                                    <img style={{height: '24px !'}} src='images/brave-logotype-dark.svg' />
                                                </div>
                                            </a>
                                        </div>

                                        <div className='game-changer-desc'
                                            // data-aos="fade-up"
                                            // data-aos-delay="400"
                                            // data-aos-duration="600"
                                        >
                                            <div className='desc-header'>Connection</div>
                                            <div className='desc-text'>
                                                Stay secure and 
                                                anonymous online
                                                with VPN solutions.
                                            </div>

                                            <a href='https://expressvpn.com' target="_blank">
                                                <div className='desc-recommend'>
                                                    Recommended:
                                                    <img style={{height: '24px !'}} src='images/expressvpn2.png' />
                                                </div>
                                            </a>
                                        </div>

                                        <div className='game-changer-desc'
                                            // data-aos="fade-up"
                                            // data-aos-delay="700"
                                            // data-aos-duration="600"
                                        >
                                            <div className='desc-header'>Data trading &#38; leaks</div>
                                            <div className='desc-text'>
                                                Control how your 
                                                personal data
                                                is being processed
                                                by third parties.
                                            </div>

                                            <div className='desc-recommend no-opacity'>
                                                <img style={{height: '24px !'}} src='images/logo-small.png' />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className={`game-changer-cta-wrapper`}
                             data-aos="fade-up"
                             data-aos-delay="300"
                             data-aos-duration="600">

                            <div className='game-changer-cta-text'
                                 data-aos="fade-up"
                                 data-aos-delay="400"
                                 data-aos-duration="600"
                            >
                                Our solution <span className='orange'>allows you to be yourself</span> and fully enjoy <br className='tablet-only'/> your <br/> online life without worrying about any invisible threats.
                                <br className='tablet-only'/><br className='desktop-only'/><span className='bold'> When a data breach happens - you're safe with us.</span>
                            </div>

                            <Link smooth to="#join" onClick={() => { Tracker.trackGoal('game-changer-protect-your-data')}}>
                                <Button className='game-changer-section-cta-button'>
                                    Create My First Identity 
                                </Button>
                            </Link>

                        </div>

                    </div>

                </div>

            </div>

        )
    }

}