import React, {Component} from 'react'
import './ProtectionSection.scss'

export default class ProtectionSection extends Component {

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    render() {


        return (

            <div id='protection'>

                <div className='section-wrapper protection-section'>

                    <div className='section-content protection-section'>

                        <div className='header'>
                            How do 
                            <br/>
                            we <span className='orange-text'> 100%</span>
                            <br className='phone-only'/> protect you<br className='tablet-only'/>?
                        </div>

                        <div className='header-image-wrapper'>
                            <img src='/images/no-spam-no-robocalling-no-bothering.png'/>
                        </div>

                        <div className='column-middle'>

                            <div className='item'>

                                <div className='icon'>
                                    <svg width="28px" height="28px" viewBox="0 0 28 28">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-642.000000, -4949.000000)">
                                                <g transform="translate(55.000000, 4907.000000)">
                                                    <g transform="translate(585.000000, 40.000000)">
                                                        <rect x="0" y="0" width="32" height="32"></rect>
                                                        <g transform="translate(2.000000, 2.000000)" fill="#5EDED2">
                                                            <path d="M14,0 C17.7130309,0 21.2739856,1.47499577 23.8994949,4.10050506 C26.5250042,6.72601436 28,10.2869691 28,14 C28,21.7319865 21.7319865,28 14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 Z M2,14 C2,20.627417 7.372583,26 14,26 C20.627417,26 26,20.627417 26,14 C26,7.372583 20.627417,2 14,2 C7.372583,2 2,7.372583 2,14 Z M11.2960543,18.8016858 L7.79318218,15.3268367 C7.35962827,14.8967512 7.35681655,14.1966332 7.78690203,13.7630793 C7.78958741,13.7603722 7.79228676,13.7576791 7.795,13.755 C8.2354417,13.3200985 8.94388869,13.3207753 9.38349857,13.7565176 L12,16.35 L18.6142811,9.78927586 C19.0546707,9.35245213 19.7650082,9.35277549 20.205,9.79 C20.6407339,10.2229934 20.6429555,10.9272361 20.2099621,11.3629699 L20.203966,11.3689581 L12.7042586,18.8020014 C12.314345,19.1884492 11.6857947,19.1883083 11.2960543,18.8016858 Z" id="Shape"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div className='header'>
                                    Your data is only <br/> accessible to you
                                </div>

                                <div className='description'>
                                    All your data is locally encrypted 
                                    and stored in a secure data vault. 
                                    No one except you can access it.
                                </div>

                            </div>

                            <div className='item'>

                                <div className='icon'>
                                    <svg width="28px" height="28px" viewBox="0 0 28 28">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-642.000000, -4949.000000)">
                                                <g transform="translate(55.000000, 4907.000000)">
                                                    <g transform="translate(585.000000, 40.000000)">
                                                        <rect x="0" y="0" width="32" height="32"></rect>
                                                        <g transform="translate(2.000000, 2.000000)" fill="#5EDED2">
                                                            <path d="M14,0 C17.7130309,0 21.2739856,1.47499577 23.8994949,4.10050506 C26.5250042,6.72601436 28,10.2869691 28,14 C28,21.7319865 21.7319865,28 14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 Z M2,14 C2,20.627417 7.372583,26 14,26 C20.627417,26 26,20.627417 26,14 C26,7.372583 20.627417,2 14,2 C7.372583,2 2,7.372583 2,14 Z M11.2960543,18.8016858 L7.79318218,15.3268367 C7.35962827,14.8967512 7.35681655,14.1966332 7.78690203,13.7630793 C7.78958741,13.7603722 7.79228676,13.7576791 7.795,13.755 C8.2354417,13.3200985 8.94388869,13.3207753 9.38349857,13.7565176 L12,16.35 L18.6142811,9.78927586 C19.0546707,9.35245213 19.7650082,9.35277549 20.205,9.79 C20.6407339,10.2229934 20.6429555,10.9272361 20.2099621,11.3629699 L20.203966,11.3689581 L12.7042586,18.8020014 C12.314345,19.1884492 11.6857947,19.1883083 11.2960543,18.8016858 Z" id="Shape"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>


                                <div className='header'>
                                    Encryption everywhere
                                </div>

                                <div className='description'>
                                    Text messages and emails 
                                    you receive are encrypted 
                                    with a public key belonging
                                    to an individual identity.
                                </div>

                            </div>

                            <div className='item'>

                                <div className='icon'>
                                    <svg width="28px" height="28px" viewBox="0 0 28 28">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-642.000000, -4949.000000)">
                                                <g transform="translate(55.000000, 4907.000000)">
                                                    <g transform="translate(585.000000, 40.000000)">
                                                        <rect x="0" y="0" width="32" height="32"></rect>
                                                        <g transform="translate(2.000000, 2.000000)" fill="#5EDED2">
                                                            <path d="M14,0 C17.7130309,0 21.2739856,1.47499577 23.8994949,4.10050506 C26.5250042,6.72601436 28,10.2869691 28,14 C28,21.7319865 21.7319865,28 14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 Z M2,14 C2,20.627417 7.372583,26 14,26 C20.627417,26 26,20.627417 26,14 C26,7.372583 20.627417,2 14,2 C7.372583,2 2,7.372583 2,14 Z M11.2960543,18.8016858 L7.79318218,15.3268367 C7.35962827,14.8967512 7.35681655,14.1966332 7.78690203,13.7630793 C7.78958741,13.7603722 7.79228676,13.7576791 7.795,13.755 C8.2354417,13.3200985 8.94388869,13.3207753 9.38349857,13.7565176 L12,16.35 L18.6142811,9.78927586 C19.0546707,9.35245213 19.7650082,9.35277549 20.205,9.79 C20.6407339,10.2229934 20.6429555,10.9272361 20.2099621,11.3629699 L20.203966,11.3689581 L12.7042586,18.8020014 C12.314345,19.1884492 11.6857947,19.1883083 11.2960543,18.8016858 Z" id="Shape"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div className='header'>
                                    Separate identities
                                </div>

                                <div className='description'>
                                    All identities and resources you
                                    create are fully separated. If any
                                    of your IDs gets compromised, 
                                    you and your other identities are safe.
                                </div>

                            </div>

                        </div>

                        <div className='column-right'>

                            <div className='item'>

                                <div className='icon'>
                                    <svg width="28px" height="28px" viewBox="0 0 28 28">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-642.000000, -4949.000000)">
                                                <g transform="translate(55.000000, 4907.000000)">
                                                    <g transform="translate(585.000000, 40.000000)">
                                                        <rect x="0" y="0" width="32" height="32"></rect>
                                                        <g transform="translate(2.000000, 2.000000)" fill="#5EDED2">
                                                            <path d="M14,0 C17.7130309,0 21.2739856,1.47499577 23.8994949,4.10050506 C26.5250042,6.72601436 28,10.2869691 28,14 C28,21.7319865 21.7319865,28 14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 Z M2,14 C2,20.627417 7.372583,26 14,26 C20.627417,26 26,20.627417 26,14 C26,7.372583 20.627417,2 14,2 C7.372583,2 2,7.372583 2,14 Z M11.2960543,18.8016858 L7.79318218,15.3268367 C7.35962827,14.8967512 7.35681655,14.1966332 7.78690203,13.7630793 C7.78958741,13.7603722 7.79228676,13.7576791 7.795,13.755 C8.2354417,13.3200985 8.94388869,13.3207753 9.38349857,13.7565176 L12,16.35 L18.6142811,9.78927586 C19.0546707,9.35245213 19.7650082,9.35277549 20.205,9.79 C20.6407339,10.2229934 20.6429555,10.9272361 20.2099621,11.3629699 L20.203966,11.3689581 L12.7042586,18.8020014 C12.314345,19.1884492 11.6857947,19.1883083 11.2960543,18.8016858 Z" id="Shape"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div className='header'>
                                    100% zero-knowledge architecture
                                </div>

                                <div className='description'>
                                    We don’t know anything about 
                                    you, your activities, or your identities.
                                    Guaranteed by pure math.
                                </div>

                            </div>

                            <div className='item'>

                               <div className='icon'>
                                   <svg width="28px" height="28px" viewBox="0 0 28 28">
                                       <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                           <g transform="translate(-642.000000, -4949.000000)">
                                               <g transform="translate(55.000000, 4907.000000)">
                                                   <g transform="translate(585.000000, 40.000000)">
                                                       <rect x="0" y="0" width="32" height="32"></rect>
                                                       <g transform="translate(2.000000, 2.000000)" fill="#5EDED2">
                                                           <path d="M14,0 C17.7130309,0 21.2739856,1.47499577 23.8994949,4.10050506 C26.5250042,6.72601436 28,10.2869691 28,14 C28,21.7319865 21.7319865,28 14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 Z M2,14 C2,20.627417 7.372583,26 14,26 C20.627417,26 26,20.627417 26,14 C26,7.372583 20.627417,2 14,2 C7.372583,2 2,7.372583 2,14 Z M11.2960543,18.8016858 L7.79318218,15.3268367 C7.35962827,14.8967512 7.35681655,14.1966332 7.78690203,13.7630793 C7.78958741,13.7603722 7.79228676,13.7576791 7.795,13.755 C8.2354417,13.3200985 8.94388869,13.3207753 9.38349857,13.7565176 L12,16.35 L18.6142811,9.78927586 C19.0546707,9.35245213 19.7650082,9.35277549 20.205,9.79 C20.6407339,10.2229934 20.6429555,10.9272361 20.2099621,11.3629699 L20.203966,11.3689581 L12.7042586,18.8020014 C12.314345,19.1884492 11.6857947,19.1883083 11.2960543,18.8016858 Z" id="Shape"></path>
                                                       </g>
                                                   </g>
                                               </g>
                                           </g>
                                       </g>
                                   </svg>
                               </div>

                               <div className='header'>
                                    Private infrastructure
                               </div>
                              
                               <div className='description'>
                                    Our service is hosted on 
                                    private and highly-secure
                                    servers located in Iceland.
                               </div>

                            </div>

                            <div className='item'>

                                <div className='icon'>
                                    <svg width="28px" height="28px" viewBox="0 0 28 28">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-642.000000, -4949.000000)">
                                                <g transform="translate(55.000000, 4907.000000)">
                                                    <g transform="translate(585.000000, 40.000000)">
                                                        <rect x="0" y="0" width="32" height="32"></rect>
                                                        <g transform="translate(2.000000, 2.000000)" fill="#5EDED2">
                                                            <path d="M14,0 C17.7130309,0 21.2739856,1.47499577 23.8994949,4.10050506 C26.5250042,6.72601436 28,10.2869691 28,14 C28,21.7319865 21.7319865,28 14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 Z M2,14 C2,20.627417 7.372583,26 14,26 C20.627417,26 26,20.627417 26,14 C26,7.372583 20.627417,2 14,2 C7.372583,2 2,7.372583 2,14 Z M11.2960543,18.8016858 L7.79318218,15.3268367 C7.35962827,14.8967512 7.35681655,14.1966332 7.78690203,13.7630793 C7.78958741,13.7603722 7.79228676,13.7576791 7.795,13.755 C8.2354417,13.3200985 8.94388869,13.3207753 9.38349857,13.7565176 L12,16.35 L18.6142811,9.78927586 C19.0546707,9.35245213 19.7650082,9.35277549 20.205,9.79 C20.6407339,10.2229934 20.6429555,10.9272361 20.2099621,11.3629699 L20.203966,11.3689581 L12.7042586,18.8020014 C12.314345,19.1884492 11.6857947,19.1883083 11.2960543,18.8016858 Z" id="Shape"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                <div className='header'>
                                    Incorporated in Iceland
                                </div>

                                <div className='description'>
                                    no.ID is registered in Iceland. 
                                    It means your data will not
                                    be retained and surveilled, 
                                    and your identity invigilated.
                                </div>

                            </div>

                        </div>

                    </div>

                    <svg id="protection-svg" width="100%" height="645px" viewBox="0 60 1271 675">
                        <g transform="translate(-93.000000, -5265.000000)" fill="#FDFAF4">
                            <g transform="translate(55.000000, 4907.000000)">
                                <g transform="translate(680.500000, 666.500000) scale(-1, 1) translate(-680.500000, -666.500000) translate(0.000000, 330.000000)">
                                    <polygon id="Rectangle" points="52.3125 68.25 1323 28 1208.63281 573.765625 155.171875 673"></polygon>
                                </g>
                            </g>
                        </g>
                    </svg>

                    <svg id="protection-svg-mobile" height="275px" viewBox="35 0 541 275">
                        <g transform="translate(41.000000, -5092.000000)" fill="#FDFAF4">
                            <g transform="translate(-41.000000, 4363.000000)">
                                <g transform="translate(301.500000, 850.500000) scale(-1, 1) translate(-301.500000, -850.500000) translate(0.000000, 697.000000)">
                                    <polygon points="62 49.1608527 603 32 554.307738 264.69077 105.792767 307"></polygon>
                                </g>
                            </g>
                        </g>
                    </svg>

                    <svg id="protection-svg-tablet" width="100%" height="393px" viewBox="10 0 777 393">
                        <g transform="translate(20.000000, -5265.000000)" fill="#FDFAF4">
                            <g transform="translate(-141.000000, 4731.000000)">
                                <g transform="translate(513.500000, 658.000000) scale(-1, 1) translate(-513.500000, -658.000000) translate(98.000000, 444.000000)">
                                    <polygon id="Rectangle" points="31 59.5244186 808 35 738.066752 367.536265 93.8964512 428"></polygon>
                                </g>
                            </g>
                        </g>
                    </svg>

                    <svg id="protection-svg-top" width="100%" height="636px" viewBox="0 0 1361 636">
                        <g transform="translate(-55.000000, -5237.000000)" fill="#EDF8F8">
                            <g transform="translate(55.000000, 4907.000000)">
                                <g transform="translate(680.500000, 666.500000) scale(-1, 1) translate(-680.500000, -666.500000) translate(0.000000, 330.000000)">
                                    <polygon id="Rectangle" points="0 60 1361 0 1246.63281 545.765625 300.855469 636"/>
                                </g>
                            </g>
                        </g>
                    </svg>

                    <svg id="protection-svg-top-tablet" width="100%" height="389px" viewBox="40 0 765 359">
                        <g transform="translate(43.000000, -5175.000000)" fill="#EDF8F8">
                            <g transform="translate(-141.000000, 4731.000000)">
                                <g transform="translate(513.500000, 658.000000) scale(-1, 1) translate(-513.500000, -658.000000) translate(98.000000, 444.000000)">
                                    <polygon points="0 36.6981132 831 0 761.169631 333.809478 183.696469 389"></polygon>
                                </g>
                            </g>
                        </g>
                    </svg>

                    <svg id="protection-svg-top-mobile" height="307px" viewBox="20 0 580 322" >
                        <g  transform="translate(18.000000, -5060.000000)" fill="#EDF8F8">
                            <g transform="translate(-41.000000, 4363.000000)">
                                <g  transform="translate(301.500000, 850.500000) scale(-1, 1) translate(-301.500000, -850.500000) translate(0.000000, 697.000000)">
                                    <polygon points="0 25.6603774 580 0 531.261595 233.409198 128.211735 272"></polygon>
                                </g>
                            </g>
                        </g>
                    </svg>

                </div>

            </div>
        )
    }
}