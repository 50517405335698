import React, {Component} from 'react'
import './UsageSection.scss'
import {Image} from "semantic-ui-react";
import auctions from "../../resources/auctions.png";


export default class UsageSection extends Component {

    render() {


        return (

            <div id='is-it-for-me' className='section-wrapper usage-section'>

                <div className='section-content usage-section'>

                    <div className='usage-header'>
                        Is no.ID for me?
                    </div>

                    <div className='usage-items'>
                        {/*<div className='usage-row' >*/}

                            <div className='usage-item'
                                 data-aos="new-slide-left"
                                 data-aos-delay="100"
                                 data-aos-duration="2500"
                                 // data-aos-easing="new-easing"
                            >
                                <img className='image' src='/images/auctions.png' />
                                <div className='text'>Online auctions</div>
                            </div>

                            <div className='usage-item'
                                 data-aos="new-slide-left"
                                 data-aos-delay="100"
                                 data-aos-duration="2500"
                                 // data-aos-easing="new-easing"
                            >
                                <Image className='image' src='/images/paid.png'/>
                                <div className='text'>Paid Subscriptions</div>
                            </div>

                            <div className='usage-item-wrapper'>
                                <div className='usage-item'
                                     data-aos="new-slide-left"
                                     data-aos-delay="500"
                                     data-aos-duration="2500"
                                     // data-aos-easing="new-easing"
                                >
                                    <Image className='image' src='/images/envelop.png'/>
                                    <div className='text'>Blogs & newsletters</div>
                                </div>
                            </div>

                        {/*</div>*/}
                        {/*<div className='usage-row'>*/}

                            <div className='usage-item'
                                 data-aos="new-slide-left"
                                 data-aos-delay="100"
                                 data-aos-duration="2500"
                            >
                                <Image className='image' src='/images/heart.png'/>
                                <div className='text'>Dating apps</div>
                            </div>

                            <div className='usage-item'
                                 data-aos="new-slide-left"
                                 data-aos-delay="400"
                                 data-aos-duration="3000"
                            >
                                <Image className='image' src='/images/chat.png'/>
                                <div className='text'>Forums & chats</div>
                            </div>

                            <div className='usage-item'
                                 data-aos="new-slide-left"
                                 data-aos-delay="400"
                                 data-aos-duration="3000"
                            >
                                <Image className='image' src='/images/cart.png'/>
                                <div className='text'>Online tools & …</div>
                            </div>

                        </div>
                    {/*</div>*/}

                    {/*<div style={{ justifyContent: 'center'}}>*/}
                    {/*    /!*<Image className='usage-section-img' src='/images/areas-of-noid-protection.png'/>*!/*/}
                    {/*    <Image className='usage-section-img' src='/images/areas-of-noid-protection-with-names.png'/>*/}
                    {/*</div>*/}

                </div>

            </div>
        )
    }
}