import React, { Component } from 'react'
import { Button, Image, Menu } from 'semantic-ui-react'
import './MenuTop.scss'
import { HashLink as Link } from "react-router-hash-link"
import Tracker from "../../trackUtils";
import { withRouter } from 'react-router-dom';

export default class MenuTop extends Component {

    state = { activeItem: 'closest' }

    handleItemClick = (e, { name }) => {
        Tracker.trackGoal('menu-' + name)
    }

    render() {
        const { activeItem } = this.state

        return (
            <>
                <div className='menu-section'
                    data-aos="fade-up"
                    data-aos-delay="500"
                    data-aos-duration="600">
                    <Image className='menu-logo' src='/images/logo-small.png' />

                    <div className="push"></div>

                    <div className='desktop-menu'>
                        <Menu text>
                            <Link smooth to="/#how-it-works">
                                <Menu.Item
                                    name="how-it-works"
                                    onClick={this.handleItemClick}
                                >How it works</Menu.Item>
                            </Link>
                            <Link smooth to="/#benefits">
                                <Menu.Item
                                    name='benefits'
                                    onClick={this.handleItemClick}
                                />
                            </Link>
                            <Link smooth to="/#how-to-use">
                                <Menu.Item
                                    name='how-to-use'
                                    onClick={this.handleItemClick}
                                >
                                    How to use
                                </Menu.Item>
                            </Link>
                            <Link smooth to="/#protection">
                                <Menu.Item
                                    name='protection'
                                    onClick={this.handleItemClick}
                                />
                            </Link>
                            <Link smooth to="/#is-it-for-me">
                                <Menu.Item
                                    name='is-it-for-me'
                                    onClick={this.handleItemClick}
                                >
                                    Is it for me?
                                </Menu.Item>
                            </Link>
                        </Menu>
                    </div>

                    <Link smooth to="/#join">
                        <Button className='menu-cta-button'
                            name='join'
                            onClick={this.handleItemClick}
                        >Get Started</Button>
                    </Link>

                    <div className='mobile-menu'>

                    <svg viewBox="0 0 100 80" width="28" height="40">
                        <rect width="100" height="8" radius="1.5" fill="#265E5F" />
                        <rect y="30" width="100" height="8" radius="1.5" fill="#265E5F" />
                        <rect y="60" width="100" height="8" radius="1.5" fill="#265E5F" />
                    </svg>

                 </div>

                </div>

                 

            </>
        )
    }
}